import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home.vue';
import Error404 from '../views/404.vue';

const slimNav = true;
export const projects = [
    {
        path: '/apartments',
        name: 'Apartments',
        thumbnail: '/images/apartments-project.jpg',
        component: () => import(/* webpackChunkName: "projects" */ '../views/Apartments.vue'),
    },
    {
        path: '/commercial',
        name: 'Commercial',
        thumbnail: '/images/commercial-project.jpg',
        component: () => import(/* webpackChunkName: "projects" */ '../views/Commercial.vue'),
    },
    {
        path: '/residential',
        name: 'Residential',
        thumbnail: '/images/residential-project.jpg',
        component: () => import(/* webpackChunkName: "projects" */ '../views/Residential.vue'),
    },
    {
        path: '/funeral-homes',
        name: 'Funeral Homes',
        thumbnail: '/images/funeral-homes-project.jpg',
        component: () => import(/* webpackChunkName: "projects" */ '../views/FuneralHomes.vue'),
    },
    {
        path: '/healthcare',
        name: 'Healthcare',
        thumbnail: '/images/healthcare-project.jpg',
        component: () => import(/* webpackChunkName: "projects" */ '../views/Healthcare.vue'),
    },
    {
        path: '/engineering',
        name: 'Engineering',
        thumbnail: '/images/engineering-project.jpg',
        component: () => import(/* webpackChunkName: "projects" */ '../views/Engineering.vue'),
    },
];

export const topLevelPages = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "top-level" */ '../views/About.vue'),
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "top-level" */ '../views/Contact.vue'),
    },
    {
        path: '/projects',
        name: 'Projects',
        component: () => import(/* webpackChunkName: "top-level" */ '../views/Projects.vue'),
    },
];

export const slightlySecretPages = [
    {
        path: '/topo',
        name: 'Topo Map',
        meta: {slimNav},
        component: () => import(/* webpackChunkName: "topo" */ '../views/Topo.vue'),
    },
    {
        path: '/:catchAll(.*)',
        name: 'Not Found',
        component: Error404,
    },
];

const routes = [
    ...topLevelPages,
    ...projects,
    ...slightlySecretPages,
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return new Promise(resolve => {
            setTimeout(() => resolve(savedPosition || {left: 0, top: 0, behavior: 'smooth'}), 100);
        });
    },
});

export default router;
