<template>
    <div class="footer">
        <div class="footer-contents" v-if="!slim">
            <footer class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <h3>Projects</h3>
                        <ul>
                            <li v-for="(project, index) in projects" v-once :key="index">
                                <router-link :to="project.path">{{ project.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-8">
                        <h3>Contact Us</h3>
                        <div>
                            <p>173-175 Victoria Street, Wellington</p>
                            <p>Phone: <a href="tel:043852046">(04) 385 2046</a></p>
                            <p>Email: <a href="mailto:office@kd.net.nz?Subject=Website%20enquiry">office@kd.net.nz</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <div class="footer-bar">
            <p>&copy; King &amp; Dawson Architects &amp; Engineers Ltd</p>
        </div>
    </div>
</template>
<script>
import {projects} from '../router';
import {computed} from 'vue';
import {useRoute} from 'vue-router';

export default {
    setup() {
        const route = useRoute();
        const slim = computed(() => route.meta.slimNav ?? false);
        return {slim, projects};
    }
};
</script>