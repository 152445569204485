<template>
    <nav class="navbar navbar-inverse text">
        <div class="container">
            <div class="navbar-header">
                <button type="button" class="navbar-toggle" @click="showMenu = !showMenu">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <router-link class="navbar-brand" to="/" @mouseup="hideMenuSoon"><img alt="King &amp; Dawson Architects &amp; Engineers Ltd" src="/images/kinganddawson.png"/></router-link>
            </div>
            <div class="collapse navbar-collapse" :class="{in: showMenu}">
                <ul class="nav navbar-nav navbar-right">
                    <nav-link path="/" name="Home" @mouseup="hideMenuSoon"/>
                    <li class="dropdown" :class="{open: showProjectsMenu}">
                        <a href="#" class="dropdown-toggle" role="button" @click="toggleDropdown" @focusout="hideTheDropdownSoon">Projects <span class="caret"></span></a>
                        <ul class="dropdown-menu" @mouseup="hideMenuSoon">
                            <nav-link :path="project.path" :name="project.name" v-for="(project, index) in projects" :key="index" v-once/>
                        </ul>
                    </li>
                    <nav-link path="/about" name="About Us" @mouseup="hideMenuSoon"/>
                    <nav-link path="/contact" name="Contact" @mouseup="hideMenuSoon"/>
                </ul>
            </div>
        </div>
    </nav>
    <div class="banner" v-if="!slim">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-center">{{ currentRouteName !== 'Home' ? currentRouteName : 'King &amp; Dawson Architects &amp; Engineers Ltd' }}</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NavLink from './NavLink.vue';
import {computed, nextTick, ref} from 'vue';
import {useRoute} from 'vue-router';
import {projects} from '../router';

export default {
    components: {
        NavLink,
    },
    setup() {
        const route = useRoute();
        const showMenu = ref(false);
        const showProjectsMenu = ref(false);
        const currentRouteName = computed(() => route.name);
        const currentPath = computed(() => route.path);
        const slim = computed(() => route.meta.slimNav ?? false);
        const toggleDropdown = () => showProjectsMenu.value = !showProjectsMenu.value;
        const hideTheDropdownSoon = () => {
            if (!showProjectsMenu.value) return;
            setTimeout(() => nextTick(() => showProjectsMenu.value = false), 100);
        };

        const hideMenuSoon = () => {
            if (!showMenu.value) return;
            setTimeout(() => nextTick(() => showMenu.value = false), 100);
        };

        return {
            showMenu, showProjectsMenu, currentRouteName, currentPath, slim, toggleDropdown, hideTheDropdownSoon, hideMenuSoon, projects,
        };
    },
};
</script>
