<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h2 class="section-header">Working at the forefront of architecture and design</h2>
                    <div class="row">
                        <div class="col-md-6">
                            <p>King &amp; Dawson architects &amp; engineers Ltd is a long-standing architectural and structural engineering firm based in Wellington, NZ. As one of the few combined practices with both architects and engineers under the same roof, we work efficiently leveraging our many years of experience in both fields. The firm has built a strong reputation in the industry and with clients - of which a large number have worked with us for many years. </p>
                        </div>
                        <div class="col-md-6">
                            <p>We pride ourselves on delivering projects on time, to budget and of high quality. We offer a variety of skills, a strong sense of integrity and professionalism, a strong focus of personal service to our clients and a methodical approach to the work we undertake. Our team can help you with all facets of your project, from initial briefing and concept design to full contract documentation and administration.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row"><h2 class="section-header">Areas we specialise in</h2>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/residential-8.png" alt="Icon of a residential house"/>
                    <p>Residential alterations &amp; new builds</p>
                </div>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/commercial-8.png" alt="Icon of office building"/>
                    <p>Commercial, offices &amp; retail</p>
                </div>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/warehousing-8.png" alt="Icon of an industrial warehouse"/>
                    <p>Industrial &amp; bulk warehousing</p>
                </div>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/medical-8.png" alt="Icon of a plus sign">
                    <p>Healthcare</p>
                </div>
                <div class="clearfix visible-md-block visible-sm-block"></div>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/seismic-8.png" alt="Icon of a seismograph line"/>
                    <p>Seismic analysis &amp; strengthening</p>
                </div>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/feasibility-8.png" alt="Icon of a magnifying glass"/>
                    <p>Feasibility studies</p>
                </div>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/leaky-building-8.png" alt="Icon of rain drops"/>
                    <p>Leaky building remediation</p>
                </div>
                <div class="col-md-3 col-sm-3 specialise">
                    <img class="img-responsive" src="/images/icons/performing-arts-8.png" alt="Icon of performing arts mask"/>
                    <p>Performing arts engineering input</p>
                </div>
            </div>

        </div>
        <div class="container-fluid text-center" id="view-work">
            <div class="row">
                <h2>Check out some of our previous work</h2><router-link id="project-button" class="btn btn-default" to="/projects">View projects</router-link>
            </div>
        </div>
    </div>
</template>