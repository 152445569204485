<template>
    <site-header-nav/>
    <router-view class="site-content"/>
    <site-footer/>
</template>

<script>
import SiteHeaderNav from '@/components/SiteHeaderNav.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
    name: 'App',
    components: {
        SiteHeaderNav,
        SiteFooter,
    },
};
</script>

<style>
@import "./css/bootstrap.css";
@import "./css/custom.css";
</style>