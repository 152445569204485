<template>
    <li :class="{active: isCurrent}">
        <router-link :to="path">{{ name }}<span v-if="isCurrent" class="sr-only">(current)</span></router-link>
    </li>
</template>

<script>
import {computed} from 'vue';
import {useRoute} from 'vue-router';
export default {
    props: {
        path: String,
        name: String,
    },
    setup(props) {
        const route = useRoute();
        const isCurrent = computed(() => route.path === props.path);
        return {isCurrent};
    },
};
</script>