<template>
    <p class="text-center">Page not found, perhaps you would like to look at our
        <router-link :to="suggestion.path">{{ suggestion.name }}</router-link> page instead.
    </p>
</template>

<script>
import {projects, topLevelPages} from '../router';

export default {
    setup() {
        const pages = [...projects, ...topLevelPages];
        const suggestion = pages[Math.random() * pages.length >> 0];
        return {suggestion};
    },
};
</script>